import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="456.28"
      height="456.28"
      viewBox="0 0 456.28 456.28"
    >
      <defs>
        <clipPath id="instagram-clip-path" transform="translate(0)">
          <rect width="456.28" height="456.28" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#instagram-clip-path)">
        <path
          d="M228.14,41.11c60.92,0,68.13.23,92.19,1.33,22.24,1,34.32,4.73,42.36,7.85a70.59,70.59,0,0,1,26.23,17.07A70.59,70.59,0,0,1,406,93.59c3.12,8,6.84,20.12,7.85,42.36,1.1,24.06,1.33,31.27,1.33,92.19s-.23,68.13-1.33,92.19c-1,22.24-4.73,34.32-7.85,42.36a75.6,75.6,0,0,1-43.3,43.3c-8,3.12-20.12,6.84-42.36,7.85-24.06,1.1-31.27,1.33-92.19,1.33S160,414.94,136,413.84c-22.24-1-34.32-4.73-42.36-7.85a70.59,70.59,0,0,1-26.23-17.07,70.71,70.71,0,0,1-17.07-26.23c-3.12-8-6.84-20.12-7.85-42.36-1.1-24.06-1.33-31.27-1.33-92.19S41.34,160,42.44,136c1-22.24,4.73-34.32,7.85-42.36A70.71,70.71,0,0,1,67.36,67.36,70.59,70.59,0,0,1,93.59,50.29c8-3.12,20.12-6.84,42.36-7.85,24.06-1.1,31.27-1.33,92.19-1.33m0-41.11c-62,0-69.73.26-94.06,1.37S93.21,6.34,78.7,12A111.94,111.94,0,0,0,38.29,38.29,111.85,111.85,0,0,0,12,78.7c-5.64,14.51-9.5,31.1-10.61,55.38S0,166.18,0,228.14s.26,69.73,1.37,94.06,5,40.87,10.61,55.38A111.85,111.85,0,0,0,38.29,418,111.94,111.94,0,0,0,78.7,444.3c14.51,5.64,31.1,9.5,55.38,10.61s32.1,1.37,94.06,1.37,69.73-.26,94.06-1.37,40.87-5,55.38-10.61a116.61,116.61,0,0,0,66.72-66.72c5.64-14.51,9.5-31.1,10.61-55.38s1.37-32.1,1.37-94.06-.26-69.73-1.37-94.06-5-40.87-10.61-55.38A111.85,111.85,0,0,0,418,38.29,111.85,111.85,0,0,0,377.58,12c-14.51-5.64-31.09-9.5-55.38-10.61S290.1,0,228.14,0"
          transform="translate(0)"
          fill={color}
        />
        <path
          d="M228.14,111A117.15,117.15,0,1,0,345.29,228.14,117.15,117.15,0,0,0,228.14,111m0,193.2a76.05,76.05,0,1,1,76.05-76.05,76.05,76.05,0,0,1-76.05,76.05"
          transform="translate(0)"
          fill={color}
        />
        <path
          d="M377.3,106.36A27.38,27.38,0,1,1,349.92,79a27.38,27.38,0,0,1,27.38,27.38"
          transform="translate(0)"
          fill={color}
        />
      </g>
    </svg>
  );
}
