import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="53"
      viewBox="0 0 76 53"
      className={className}
    >
      <g transform="translate(14748.073 7180.568)">
        <g transform="translate(-14748.073 -7180.568)" fill="none" stroke={color} strokeWidth="4">
          <rect width="76" height="53" rx="6" stroke="none" />
          <rect x="2" y="2" width="72" height="49" rx="4" fill="none" />
        </g>
        <path
          d="M-14739.789-7172.518s31.1,31.021,32.181,31.648,3.039,2.179,5.982.175,32.473-31.823,32.473-31.823"
          transform="translate(-5.718 -5.379)"
          fill="none"
          stroke={color}
          strokeWidth="4"
        />
        <path
          d="M-14739.993-7063.151l24.926-24.716"
          transform="translate(-6.076 -66.19)"
          fill="none"
          stroke={color}
          strokeWidth="4"
        />
        <path
          d="M-14579.688-7085.966l23.982,24"
          transform="translate(-119.32 -67.533)"
          fill="none"
          stroke={color}
          strokeWidth="4"
        />
      </g>
    </svg>
  );
}
