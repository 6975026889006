import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSpring, animated } from "@react-spring/web";
import { useGesture } from "@use-gesture/react";
import clsx from "clsx";

import styles from "./companies.module.css";
import CompanyDetail from "./companyDetail";

const AnimatedCompanyDetail = animated(CompanyDetail);

const Companies = ({ initialActiveCompanyId, className, companies, onClose }) => {
  const [activeCompanyId, setActiveCompanyId] = useState(initialActiveCompanyId);
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)"
  });
  const axis = isMobile ? "x" : "y";

  let indexById = {};
  companies.forEach((element, index) => {
    indexById[element.id] = index;
  });
  const activeCompanyIndex = indexById[activeCompanyId];

  let activeCompany, prevCompany, nextCompany;
  activeCompany = companies[activeCompanyIndex];
  if (!activeCompany) {
    return <div></div>;
  }

  let prevCompanyIndex = activeCompanyIndex - 1;
  if (prevCompanyIndex < 0) {
    prevCompanyIndex = companies.length - 1;
  }
  prevCompany = companies[prevCompanyIndex];

  let nextCompanyIndex = activeCompanyIndex + 1;
  if (nextCompanyIndex > companies.length - 1) {
    nextCompanyIndex = 0;
  }
  nextCompany = companies[nextCompanyIndex];

  useEffect(() => {
    activeCompany = companies[activeCompanyIndex];
    if (!activeCompany) {
      return <div></div>;
    }

    let prevCompanyIndex = activeCompanyIndex - 1;
    if (prevCompanyIndex < 0) {
      prevCompanyIndex = companies.length - 1;
    }
    prevCompany = companies[prevCompanyIndex];

    let nextCompanyIndex = activeCompanyIndex + 1;
    if (nextCompanyIndex > companies.length - 1) {
      nextCompanyIndex = 0;
    }
    nextCompany = companies[nextCompanyIndex];
  }, [activeCompanyIndex]);

  const handleClose = () => {
    onClose();
  };

  const goPrev = () => {
    prevApi.start({
      [axis]: "0%",
      onRest: () => {
        prevApi.set({
          [axis]: "-100%"
        });
        setActiveCompanyId(prevCompany.id);
      }
    });
  };
  const goNext = () => {
    nextApi.start({
      [axis]: "0%",
      onRest: () => {
        nextApi.set({
          [axis]: "100%"
        });
        setActiveCompanyId(nextCompany.id);
      }
    });
  };
  const handlePrev = () => {
    goPrev();
  };
  const handleNext = () => {
    goNext();
  };

  const [prevStyles, prevApi] = useSpring(() => ({
    [axis]: "-100%"
  }));
  const [nextStyles, nextApi] = useSpring(() => ({
    [axis]: "100%"
  }));

  const pixelsToPercent = (pixels, axis) => {
    let pixelQuantity;
    if (axis === "x") {
      pixelQuantity = window.innerWidth;
    } else {
      pixelQuantity = window.innerHeight;
    }
    return (pixels / pixelQuantity) * 100;
  };

  const handleEndGesture = reverseDirection => state => {
    const {
      movement: [x, y]
    } = state;
    let amount = axis === "x" ? x : y;
    if (reverseDirection) {
      amount = amount * -1;
    }
    if (amount > 0) {
      goNext();
    } else {
      goPrev();
    }
  };

  const handleMoveGesture = reverseDirection => state => {
    const {
      movement: [x, y]
    } = state;
    let amount = axis === "x" ? x : y;

    if (reverseDirection) {
      amount = amount * -1;
    }
    if (amount > 0) {
      nextApi.start({
        [axis]: 100 - pixelsToPercent(amount, axis) + "%"
      });
    } else {
      prevApi.start({
        [axis]: pixelsToPercent(amount, axis) - 100 + "%"
      });
    }
  };

  const bindGestures = useGesture(
    {
      onDrag: handleMoveGesture(true),
      onDragEnd: handleEndGesture(true),
      onWheel: handleMoveGesture(),
      onWheelEnd: handleEndGesture
    },
    {
      // target: window,
      drag: { axis },
      wheel: { axis }
    }
  );

  return (
    <div className={clsx(styles.root, className)} {...bindGestures()}>
      <AnimatedCompanyDetail
        company={activeCompany}
        onClose={handleClose}
        onPrev={handlePrev}
        onNext={handleNext}
      />
      <AnimatedCompanyDetail
        style={prevStyles}
        company={prevCompany}
        onClose={handleClose}
        onPrev={handlePrev}
        onNext={handleNext}
      />
      <AnimatedCompanyDetail
        style={nextStyles}
        company={nextCompany}
        onClose={handleClose}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </div>
  );
};

export default Companies;
