import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65.483"
      height="53.219"
      viewBox="0 0 65.483 53.219"
      className={className}
    >
      <path
        d="M95.594,151.63c24.712,0,38.226-20.473,38.226-38.226,0-.582-.012-1.161-.038-1.737a27.336,27.336,0,0,0,6.7-6.956,26.818,26.818,0,0,1-7.716,2.115,13.48,13.48,0,0,0,5.908-7.432,26.923,26.923,0,0,1-8.531,3.261,13.447,13.447,0,0,0-22.9,12.253,38.142,38.142,0,0,1-27.692-14.037,13.446,13.446,0,0,0,4.159,17.936,13.335,13.335,0,0,1-6.085-1.681c0,.056,0,.112,0,.171a13.441,13.441,0,0,0,10.778,13.171,13.415,13.415,0,0,1-6.067.23,13.45,13.45,0,0,0,12.551,9.331A26.959,26.959,0,0,1,78.2,145.782,27.327,27.327,0,0,1,75,145.6a38.035,38.035,0,0,0,20.594,6.035"
        transform="translate(-75 -98.411)"
        fill={color}
        stroke="none"
      />
    </svg>
  );
}
