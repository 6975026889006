import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.7"
      height="25.7"
      viewBox="0 0 27.7 25.7"
      className={className}
    >
      <g transform="translate(-249.6 361.85)">
        <ellipse
          cx="13"
          cy="12"
          rx="13"
          ry="12"
          transform="translate(250.45 -361)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M280.587-182.8a17.946,17.946,0,0,1,10.609-3.359,17.981,17.981,0,0,1,10.426,3.227"
          transform="translate(-27.674 -158.189)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M301.622-318.159a17.945,17.945,0,0,1-10.609,3.359,17.981,17.981,0,0,1-10.425-3.227"
          transform="translate(-27.674 -38.761)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M316.149-333.521a13.315,13.315,0,0,1-6.649-11.767,13.366,13.366,0,0,1,6.28-11.557"
          transform="translate(-52.886 -3.76)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <path
          d="M373-334.021a13.315,13.315,0,0,0,6.649-11.767,13.366,13.366,0,0,0-6.28-11.557"
          transform="translate(-109.328 -3.308)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <line
          x2="26.805"
          transform="translate(250.048 -348.952)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <line
          y1="24"
          transform="translate(263.45 -361)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
      </g>
    </svg>
  );
}
