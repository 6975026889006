import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useSpring, animated, config as SpringConfig } from "@react-spring/web";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import CompanyPreviewGrid from "../components/company-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Header from "../components/header";
import Companies from "../components/companies";
import ReadSection from "../components/ReadSection/read-section";

import layoutStyles from "../components/layout.module.css";
import styles from "./index.module.css";

import TwitterIcon from "../components/icon/twitter";
import InstagramIcon from "../components/icon/instagram";
import LinkedInIcon from "../components/icon/linkedin";
import MediumIcon from "../components/icon/medium";
import EmailIcon from "../components/icon/email";

export const numRowsByNumCompanies = {
  "8": 11,
  "9": 13,
  "10": 15,
  "11": 20,
  "12": 20,
  "13": 21,
  "14": 23,
  "15": 25,
  "16": 27,
  "18": 29,
  "19": 31,
  "20": 33
};

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      linkedIn
      medium
      twitter
      instagram
      email
      mainImage {
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
        hotspot {
          x
          y
        }
      }
      mainVideo {
        mp4 {
          asset {
            url
            mimeType
          }
        }
      }
    }
    aboutDavid: sanityAboutDavid {
      _rawText(resolveReferences: { maxDepth: 10 })
    }
    companies: allSanityInvestment(
      sort: { fields: [orderRank], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          investmentYear
          roles
          color
          website
          twitter
          instagram
          news {
            publisher
            title
            url
            _key
          }
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;
  const [activeCompanyId, setActiveCompanyId] = useState(undefined);
  const [activeBillboard, setActiveBillboard] = useState();
  const seePage = useRef();
  const seePageFooter = useRef();
  const readPage = useRef();
  const readPageFooter = useRef();
  const footerModeButtonMask = useRef();
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)"
  });
  const [readMode, setReadMode] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [seePageStyles, seePageSpringApi] = useSpring(() => ({
    clipPath: `none`,
    config: SpringConfig.slow,
    onRest: () => {
      seePage.current.style.clipPath = "none";
    }
  }));
  const [readPageStyles, readPageSpringApi] = useSpring(() => ({
    clipPath: "circle(0px at 200px 200px)", // location doesn't matter, just need a pinpoint as initial style
    config: SpringConfig.slow,
    onRest: () => {
      readPage.current.style.clipPath = "none";
    }
  }));

  const [bubbleStyles, bubbleStylesApi] = useSpring(() => ({
    bottom: isMobile ? "9vw" : "10vw",
    scale: 1,
    config: SpringConfig.slow
  }));

  const getPageScrollMarker = pageEl => {
    const scrollMarkers = pageEl.querySelectorAll("[data-scroll-marker]");
    for (let i = 0; i < scrollMarkers.length; i++) {
      const marker = scrollMarkers[i];
      const top = marker.getBoundingClientRect(0).top;
      if (top >= 0) {
        return marker.dataset.scrollMarker;
      }
    }
    return "footer";
  };

  useEffect(() => {
    if (readMode) {
      readPage.current.focus();
      const btn = document.querySelector(".modeButton");
      const { top, left, height, width } = btn.getBoundingClientRect();
      const originLeft = left + width / 2;
      const originTop = top + height / 2;
      const originClipPath = `circle(0px at ${originLeft}px ${originTop}px)`;
      const maxSize = Math.max(window.innerWidth, window.innerHeight) * 1.15;
      const targetClipPath = `circle(${maxSize}px at ${originLeft}px ${originTop}px)`;
      seePage.current.style.zIndex = 0;
      readPage.current.style.zIndex = 1000;

      const targetScrollMarkerName = getPageScrollMarker(seePage.current);
      const targetScrollMarkerEl = readPage.current.querySelector(
        `[data-scroll-marker=${targetScrollMarkerName}]`
      );
      readPage.current.scroll(
        0,
        targetScrollMarkerName === "hero"
          ? 0
          : readPage.current.scrollTop + targetScrollMarkerEl.getBoundingClientRect().top
      );

      readPageSpringApi.start({
        from: {
          clipPath: originClipPath
        },
        to: {
          clipPath: targetClipPath
        }
      });
    } else {
      seePage.current.focus();
      const btn = document.querySelector(".modeButton");
      const { top, left, height, width } = btn.getBoundingClientRect();
      const originLeft = left + width / 2;
      const originTop = top + height / 2;
      let originClipPath = `circle(0px at ${originLeft}px ${originTop}px)`;
      const maxSize = Math.max(window.innerWidth, window.innerHeight) * 1.15;
      const targetClipPath = `circle(${maxSize}px at ${originLeft}px ${originTop}px)`;
      seePage.current.style.zIndex = 1000;
      readPage.current.style.zIndex = 0;
      const targetScrollMarkerName = getPageScrollMarker(readPage.current);
      const targetScrollMarkerEl = seePage.current.querySelector(
        `[data-scroll-marker=${targetScrollMarkerName}]`
      );
      if (firstLoad) {
        originClipPath = targetClipPath;
        setFirstLoad(false);
      }
      seePage.current.scroll(
        0,
        targetScrollMarkerName === "hero"
          ? 0
          : seePage.current.scrollTop + targetScrollMarkerEl.getBoundingClientRect().top
      );
      seePageSpringApi.start({
        from: {
          clipPath: originClipPath
        },
        to: {
          clipPath: targetClipPath
        }
      });
    }
  }, [readMode]);

  useEffect(() => {
    if (activeBillboard) {
      document.querySelectorAll(".page").forEach(el => {
        el.style.overflowY = "hidden";
      });
      readPage?.current?.classList.remove("dark-mode");
      bubbleStylesApi.start({
        to: {
          bottom: isMobile ? "9vw" : "5.5vw",
          scale: 0.15
        }
      });
    } else if (!firstLoad) {
      document.querySelectorAll(".page").forEach(el => {
        el.style.overflowY = "auto";
      });
      readPage?.current?.classList.add("dark-mode");
      bubbleStylesApi.start({
        to: {
          bottom: isMobile ? "5vw" : "10vw",
          scale: 1
        }
      });
    }
  }, [activeBillboard]);

  const handleModeButtonClick = () => {
    if (activeBillboard) {
      setActiveBillboard(undefined);
      //
    } else {
      setReadMode(!readMode);
    }
  };

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const projectNodes = (data || {}).companies
    ? mapEdgesToNodes(data.companies).filter(filterOutDocsWithoutSlugs)
    : [];
  let companies = projectNodes.filter(node => node.mainImage && node.mainImage.asset);

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const handleCompanyClick = companyId => {
    setActiveCompanyId(companyId);
    document.querySelectorAll(".page").forEach(el => {
      el.style.overflowY = "hidden";
    });
  };

  const handleCompanyClose = () => {
    setActiveCompanyId(undefined);
    document.querySelectorAll(".page").forEach(el => {
      el.style.overflowY = "auto";
    });
  };

  return (
    <Layout gridBackground={true}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        {/* /#seePage:start */}
        <animated.div
          id="seePage"
          className={clsx("page", styles.seePage)}
          style={seePageStyles}
          ref={seePage}
          tabIndex={0}
          onScroll={e => {
            const topOfMask = seePageFooter.current.offsetTop - e.target.scrollTop;
            footerModeButtonMask.current.style.top = `${topOfMask}px`;
          }}
        >
          <div className="grid-background"></div>

          <div className={styles.nav}>
            <Header
              siteTitle={site.title}
              activeBillboard={activeBillboard}
              setActiveBillboard={setActiveBillboard}
            />
          </div>

          <div className={layoutStyles.content}>
            <div className={layoutStyles.sidebarOffset}>
              <div className={styles.heroImageContainer}>
                <div className={styles.heroVideoContainer} data-scroll-marker="hero">
                  {site.mainImage && (
                    <Img
                      fluid={site.mainImage.asset.fluid}
                      className={styles.gatsbyHeroImage}
                      imgStyle={{
                        objectPosition: `${site.mainImage.hotspot.x * 100}% ${site.mainImage.hotspot
                          .y * 100}%`
                      }}
                    />
                  )}
                  {site.mainVideo && !site.mainImage && (
                    <video
                      className={styles.heroVideo}
                      src={site.mainVideo.mp4.asset.url}
                      autoPlay
                      muted
                      loop
                      playsInline
                    />
                  )}
                </div>
              </div>
            </div>
            {companies && (
              <CompanyPreviewGrid companies={companies} onCompanyClick={handleCompanyClick} />
            )}
          </div>

          <footer className={styles.footer} ref={seePageFooter} data-scroll-marker="footer">
            <div className={clsx(layoutStyles.sidebarOffset, styles.footerSidebarOffset)}>
              <div className={styles.footerContent}>
                <div className={styles.footerSocialLinks}>
                  {site.twitter && (
                    <a href={site.twitter} target="_blank" rel="noreferrer">
                      <TwitterIcon />
                    </a>
                  )}
                  {site.instagram && (
                    <a href={site.instagram} target="_blank" rel="noreferrer">
                      <InstagramIcon />
                    </a>
                  )}
                  {site.linkedIn && (
                    <a href={site.linkedIn} target="_blank" rel="noreferrer">
                      <LinkedInIcon />
                    </a>
                  )}
                  {site.medium && (
                    <a href={site.medium} target="_blank" rel="noreferrer">
                      <MediumIcon />
                    </a>
                  )}
                  {site.email && (
                    <a href={`mailto:${site.email}`}>
                      <EmailIcon />
                    </a>
                  )}
                </div>
                <div className={styles.footerSiteInfo}>Copyright {new Date().getFullYear()}</div>
              </div>
            </div>
          </footer>
        </animated.div>
        {/* /#seePage:end */}

        {/* #readPage:start */}
        <animated.div
          id="readPage"
          className={clsx("page", "dark-mode", styles.readPage)}
          tabIndex={0}
          ref={readPage}
          style={readPageStyles}
          onScroll={e => {
            const topOfMask = readPageFooter.current.offsetTop - e.target.scrollTop;
            footerModeButtonMask.current.style.top = `${topOfMask}px`;
          }}
        >
          <div className={styles.nav}>
            <Header
              siteTitle={site.title}
              activeBillboard={activeBillboard}
              setActiveBillboard={setActiveBillboard}
            />
          </div>

          <div className={layoutStyles.content}>
            <ReadSection data={data} companies={companies} onCompany={handleCompanyClick} />
          </div>

          <footer
            className={clsx("footer", styles.footer)}
            ref={readPageFooter}
            data-scroll-marker="footer"
          >
            <div className={clsx(layoutStyles.sidebarOffset, styles.footerSidebarOffset)}>
              <div className={styles.footerContent}>
                <div className={clsx(styles.footerSocialLinks, styles.textLinks)}>
                  {site.twitter && (
                    <a href={site.twitter} target="_blank" rel="noreferrer">
                      Twitter
                    </a>
                  )}
                  {site.instagram && (
                    <a href={site.instagram} target="_blank" rel="noreferrer">
                      Instagram
                    </a>
                  )}
                  {site.linkedIn && (
                    <a href={site.linkedIn} target="_blank" rel="noreferrer">
                      LinkedIn
                    </a>
                  )}
                  {site.medium && (
                    <a href={site.medium} target="_blank" rel="noreferrer">
                      Medium
                    </a>
                  )}
                  {site.email && <a href={`mailto:${site.email}`}>Email</a>}
                </div>
                <div className={styles.footerSiteInfo}>Copyright {new Date().getFullYear()}</div>
              </div>
            </div>
          </footer>
        </animated.div>
        {/* #readPage:end */}

        {/* MODE BUTTON : start */}
        <div className={styles.modeButtonContainer}>
          <animated.button
            className={clsx(
              "modeButton",
              styles.btnCircle,
              readMode && styles.btnCircleInverted,
              activeBillboard && styles.btnCircleDot,
              !readMode && styles.btnRead
            )}
            style={bubbleStyles}
            onClick={handleModeButtonClick}
          >
            {readMode ? "see" : "read"}
          </animated.button>
        </div>
        <div className={clsx(styles.footerModeButtonMask)} ref={footerModeButtonMask}>
          <div className={styles.modeButtonContainer}>
            <animated.button
              className={clsx(
                styles.btnCircle,
                !readMode && styles.btnCircleInverted,
                activeBillboard && styles.btnCircleDot,
                !readMode && styles.btnRead
              )}
              style={bubbleStyles}
              onClick={handleModeButtonClick}
            >
              {readMode ? "see" : "read"}
            </animated.button>
          </div>
        </div>
        {/* MODE BUTTON : end */}

        <CSSTransition
          in={!!activeCompanyId}
          timeout={1000}
          classNames="company-transition"
          unmountOnExit
        >
          <Companies
            className={styles.companies}
            companies={companies}
            onClose={handleCompanyClose}
            initialActiveCompanyId={activeCompanyId}
          />
        </CSSTransition>
      </Container>
    </Layout>
  );
};

export default IndexPage;
