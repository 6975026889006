import React, { useEffect, useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import styles from "./companyDetail.module.css";
import Img from "gatsby-image";
import clsx from "clsx";

import hexToHSL from "../lib/hexToHSL";
import GlobeIcon from "./icon/globe";
import CloseIcon from "./icon/close";
import UpIcon from "./icon/up";
import DownIcon from "./icon/down";
import TwitterIcon from "./icon/twitter";
import InstagramIcon from "./icon/instagram";

const CompanyDetail = React.forwardRef(
  ({ className, company, onClose, onPrev, onNext, style }, ref) => {
    const handleClose = () => {
      onClose();
    };
    const handlePrev = () => {
      onPrev();
    };
    const handleNext = () => {
      onNext();
    };

    const {
      id,
      color,
      mainImage,
      title,
      investmentYear,
      roles,
      news,
      website,
      twitter,
      instagram
    } = company;

    const { l: backgroundLuminosity } = hexToHSL(color);
    const textColor = backgroundLuminosity > 60 ? "#000" : "#FFF";

    const [activeNewsSlideIndex, setActiveNewsSlideIndex] = useState(0);

    let newsItemTransition;
    let activeNewsItem = news[activeNewsSlideIndex];

    newsItemTransition = useTransition(activeNewsItem, {
      key: activeNewsItem?._key,
      from: { x: "100%" },
      enter: { x: "0%" },
      leave: { x: "-100%" },
      reset: true
    });

    useEffect(() => {
      const slideTimer = setInterval(() => {
        setActiveNewsSlideIndex(state => (state + 1) % news.length);
      }, 4000);
      return () => clearInterval(slideTimer);
    }, [company]);

    return (
      <animated.div
        key={id}
        className={clsx(styles.company, className)}
        style={{ backgroundColor: color, color: textColor, ...style }}
        ref={ref}
      >
        <div className={styles.imageContainer}>
          <Img fluid={mainImage.asset.fluid} className={styles.gatsbyImg} />
          <div className={styles.colorOverlay} style={{ backgroundColor: color }}></div>
        </div>

        <div className={styles.contentContainer}>
          <div className={clsx(styles.navButtonContainer, styles.navButtonContainerUp)}>
            <button className={styles.upButton} onClick={handleNext}>
              <UpIcon className={styles.upIcon} />
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.contentTop}>
              <h2 className={clsx("h2", styles.heading)}>{title}</h2>
              <button className={styles.btnClose} onClick={handleClose}>
                <CloseIcon className={styles.closeIcon} />
              </button>
            </div>
            <div className={styles.contentBottom}>
              <div className={styles.contentBottomTop}>
                <div className={styles.invested}>
                  <div className="caption">Invested Since</div>
                  <h3>{investmentYear}</h3>
                </div>
                <div className={styles.roles}>
                  <div className="caption">Role</div>
                  {roles && roles.map((role, index) => <h3 key={index}>{role}</h3>)}
                </div>
                <div className={styles.social}>
                  {website && (
                    <a className={styles.socialImg} href={website} target="_blank" rel="noreferrer">
                      <GlobeIcon />
                    </a>
                  )}
                  {twitter && (
                    <a className={styles.socialImg} href={twitter} target="_blank" rel="noreferrer">
                      <TwitterIcon />
                    </a>
                  )}
                  {instagram && (
                    <a
                      className={styles.socialImg}
                      href={instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                  )}
                </div>
              </div>
              <div className={styles.contentBottomBottom}>
                <div className={styles.newsList}>
                  {newsItemTransition((props, item) => (
                    <animated.div className={clsx(styles.news)} key={item._key} style={props}>
                      <a className="caption" href={item.url} target="_blank" rel="noreferrer">
                        {item.publisher}
                      </a>
                      <a
                        className={clsx("h3", styles.newsTitle)}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.title}
                      </a>
                    </animated.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(styles.navButtonContainer, styles.navButtonContainerDown)}>
            <button className={styles.downButton} onClick={handlePrev}>
              <DownIcon className={styles.downIcon} />
            </button>
          </div>
        </div>
      </animated.div>
    );
  }
);

export default CompanyDetail;
