import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53.221"
      height="53.219"
      viewBox="0 0 53.221 53.219"
      className={className}
    >
      <g transform="translate(0 0)">
        <path
          d="M473.568,0H428.215a3.884,3.884,0,0,0-3.928,3.835V49.38a3.887,3.887,0,0,0,3.928,3.84h45.353a3.894,3.894,0,0,0,3.939-3.84V3.836A3.892,3.892,0,0,0,473.568,0Zm-33.5,45.35h-7.894v-25.4h7.894Zm-3.945-28.87A4.576,4.576,0,1,1,440.7,11.9,4.578,4.578,0,0,1,436.126,16.481Zm33.511,28.87h-7.888V33c0-2.945-.054-6.734-4.1-6.734-4.107,0-4.738,3.208-4.738,6.521V45.351h-7.886v-25.4h7.571v3.471h.106a8.294,8.294,0,0,1,7.469-4.1c7.993,0,9.469,5.261,9.469,12.1Z"
          transform="translate(-424.287 -0.001)"
          fill={color}
        />
      </g>
    </svg>
  );
}
