import React from "react";

const serializers = {
  marks: {
    underline: ({children}) => (<span style={{borderBottom: "1px solid currentColor"}}>{children}</span>),
    sup: ({children}) => (<sup>{children}</sup>),
    link: ({ mark, children }) => {
      const { href } = mark;
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" className="blockContentLink">
          {children}
        </a>
      );
    }
  },
  types: {}
};

export default serializers;
