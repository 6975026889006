import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { config as SpringConfig, useSpring, useTransition, animated } from "@react-spring/web";

import styles from "./header.module.css";
import ravenImg from "../assets/billboards/HF_Raven_Final.png";
import hellerImg from "../assets/billboards/Highline_Final.png";
import ragaImg from "../assets/billboards/RagaPartners_billboard.png";
import clsx from "clsx";

const billboardData = [
  {
    id: "heller",
    title: "HELLER FOUNDATION",
    img: hellerImg,
    text: "GIVING SINCE 1997"
  },
  {
    id: "raga",
    title: "RAGA PARTNERS",
    img: ragaImg,
    text: (
      <>
        INVESTING PARTNER <br className="showOnMobile" />
        SINCE 2013
      </>
    ),
    url: "https://www.ragapartners.com/"
  },
  {
    id: "41r",
    title: "FORTY ONE RAVENS",
    img: ravenImg,
    text: "FOR 2022 AND BEYOND"
  }
];

const Billboard = animated(({ id, img, text, url, style }) => {
  const children = (
    <>
      <animated.div className={styles.billboardImgWrapper} style={{ opacity: style.innerOpacity }}>
        <img className={styles.billboardImg} src={img} />
      </animated.div>
      <animated.h1 className={styles.billboardText} style={{ opacity: style.innerOpacity }}>
        {text}
      </animated.h1>
    </>
  );

  return (
    <animated.div className={styles.billboard} data-billboard-name={id} style={style}>
      {url ? (
        <animated.a
          href={url}
          target="_blank"
          rel="noopener"
          style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}
        >
          {children}
        </animated.a>
      ) : (
        children
      )}
    </animated.div>
  );
});

const HeaderItems = ({ activeBillboard, siteTitle, onMainTitleClick, onBillboardClick }) => {
  return (
    <>
      <Link
        className={clsx(styles.headerItem, styles.siteTitleItem)}
        to="/"
        onClick={onMainTitleClick}
        data-active={!activeBillboard}
      >
        {siteTitle}
      </Link>
      {billboardData.map(item => (
        <button
          key={item.id}
          className={clsx(styles.headerItem, styles.billboardHeaderItem)}
          onClick={onBillboardClick(item)}
          data-active={activeBillboard === item}
          data-billboard-name={item.id}
        >
          {item.title}
        </button>
      ))}
    </>
  );
};

const Header = ({ siteTitle, activeBillboard, setActiveBillboard }) => {
  const navRef = useRef();
  const [processingClickFromNav, setProcessingClickFromNav] = useState(false);
  const [{ scrollLeft }, scrollLeftApi] = useSpring(() => ({
    scrollLeft: 0,
    onRest: () => {
      setProcessingClickFromNav(false);
      // Set scrollLeft to the first instance – hack to maintain right scroll
      const firstActiveEl = navRef.current.querySelector('[data-active="true"]');
      const left = getNavScrollLeft(firstActiveEl);
      scrollLeftApi.set({
        scrollLeft: left
      });
    }
  }));

  useEffect(() => {
    if (!activeBillboard && !processingClickFromNav) {
      scrollLeftApi.start({ to: 0 });
    }
  }, [activeBillboard]);

  const billboardTransition = useTransition(activeBillboard, {
    key: activeBillboard?.id,
    from: {
      x: "100%",
      innerOpacity: 0
    },
    enter: {
      x: "0%",
      innerOpacity: 1
    },
    leave: {
      x: "-100%",
      innerOpacity: 0
    },
    config: SpringConfig.slow
  });

  const getNavScrollLeft = navItemEl => {
    const pageMarginLeft = window.innerWidth * 0.05; // 5vw
    const offsetLeft = navItemEl.offsetLeft;
    return offsetLeft - pageMarginLeft;
  };

  const handleMainTitleClick = e => {
    setProcessingClickFromNav(true);
    setActiveBillboard(undefined);
    scrollLeftApi.start({ to: getNavScrollLeft(e.target) });
  };

  const handleBillboardClick = billboard => e => {
    setProcessingClickFromNav(true);
    setActiveBillboard(billboard);
    scrollLeftApi.start({ to: getNavScrollLeft(e.target) });
  };

  return (
    <div className={styles.root}>
      <animated.div className={styles.nav} scrollLeft={scrollLeft} ref={navRef}>
        <HeaderItems
          activeBillboard={activeBillboard}
          siteTitle={siteTitle}
          onMainTitleClick={handleMainTitleClick}
          onBillboardClick={handleBillboardClick}
        />
        {/* Duplicate items for "infinite" scroll so that 41R can appear fully to the left when active */}
        <HeaderItems
          activeBillboard={activeBillboard}
          siteTitle={siteTitle}
          onMainTitleClick={handleMainTitleClick}
          onBillboardClick={handleBillboardClick}
        />
      </animated.div>

      {billboardTransition((props, billboard) => (
        <Billboard {...billboard} style={props} />
      ))}
    </div>
  );
};

export default Header;
