import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.707"
      height="15.207"
      viewBox="0 0 29.707 15.207"
      className={className}
    >
      <g transform="translate(1812.854 975.354) rotate(180)">
        <line
          x1="14.5"
          y2="14.5"
          transform="translate(1812.5 975) rotate(180)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line
          x1="14.5"
          y1="14.5"
          transform="translate(1798 975) rotate(180)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
