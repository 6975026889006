import React from "react";
import clsx from "clsx";

import styles from "./container.module.css";

const Container = ({ className, children }) => {
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

export default Container;
