import React from "react";

export default function({ className, color = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.707"
      height="23.707"
      viewBox="0 0 23.707 23.707"
      className={className}
    >
      <g transform="translate(0.354 0.354)">
        <line
          x2="23"
          y2="23"
          transform="translate(23) rotate(90)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line x2="23" y2="23" fill="none" stroke={color} strokeWidth="1" />
      </g>
    </svg>
  );
}
