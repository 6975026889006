import React from "react";
import Img from "gatsby-image";
import clsx from "clsx";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import styles from "./company-preview-grid.module.css";

// function getNumberDisplay(index) {
//   return String(index + 1).padStart(2, "0");
// }

const imgClassnameByIndex = {
  "0": `${styles.img} ${styles.right}`,
  "1": `${styles.imgVertical}`,
  "2": `${styles.img}`,
  "3": `${styles.imgVertical} ${styles.imgRight} ${styles.right}`,
  "4": `${styles.img}`,
  "5": `${styles.img} ${styles.right}`,
  "6": `${styles.imgVertical} ${styles.imgRight}`,
  "7": `${styles.img}`,
  "8": `${styles.img} ${styles.right}`,
  "9": `${styles.img}`,
  "10": `${styles.imgVertical} ${styles.imgRight}`,
  "11": `${styles.imgVertical}`,
  "12": `${styles.img}`,
  "13": `${styles.img} ${styles.right}`,
  "14": `${styles.imgVertical}`,
  "15": `${styles.img}`,
  "16": `${styles.img}`,
  "17": `${styles.imgVertical}`,
  "18": `${styles.imgVertical} ${styles.imgRight} ${styles.right}`,
  "19": `${styles.img}`
};

import { numRowsByNumCompanies } from "../pages/index";

function CompanyPreviewGrid({ companies, onCompanyClick }) {
  // console.log("CompanyPreviewGrid companies", companies);

  const handleClick = companyId => {
    onCompanyClick(companyId);
  };

  return (
    <div
      className={styles.grid}
      style={{
        gridTemplateRows: `repeat(${numRowsByNumCompanies[companies.length] || 23}, calc(100vw/6))`
      }}
    >
      {companies.map((node, index) => (
        <div key={node.id} className={styles.company} data-scroll-marker={`company-${node.id}`}>
          <button
            className={clsx(styles.companyLink, imgClassnameByIndex[index])}
            onClick={() => handleClick(node.id)}
          >
            <Img fluid={node.mainImage.asset.fluid} alt={node.title} className={styles.gatsbyImg} />
            <div className={styles.colorOverlay} style={{ backgroundColor: node.color }}></div>
          </button>
        </div>
      ))}
    </div>
  );
}

CompanyPreviewGrid.defaultProps = {
  companies: []
};

export default CompanyPreviewGrid;

{
  /* // <img
//   src={imageUrlFor(buildImageObj(node.mainImage))
//     .width(600)
//     .height(Math.floor((9 / 16) * 600))
//     .url()}
//   alt=""
// /> */
}
