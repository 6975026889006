import React from "react";
import clsx from "clsx";
import BlockText from "../block-text";
import styles from "./read-section.module.css";
import layoutStyles from "../layout.module.css";

export default function ReadSection({ data, companies, onCompany }) {
  return (
    <>
      <section className={clsx(styles.readSection, layoutStyles.sidebarOffset)} data-scroll-marker="hero">
        <div className={clsx(styles.readBlock, styles.topBlock)}>
          <div className={styles.aboutDavid}>
            <BlockText blocks={data.aboutDavid._rawText} />
          </div>
        </div>
      </section>
      <section className={clsx(styles.readSection, layoutStyles.sidebarOffset)}>
        <div className={styles.readBlock}>
          <div className={styles.companies}>
            {companies.map((item, idx) => (
              <button
                key={item.id}
                className={styles.companiesItem}
                onClick={() => onCompany(item.id)}
                data-scroll-marker={`company-${item.id}`}
              >
                <sup>{`0${idx + 1}`.substr(-2)}</sup>
                <span>{item.title}</span>
              </button>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
